<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div class="custom-tab-card">
      <div class="row">
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeNameAr'"
          :value="employeeData.employeeNameAr"
          v-on:changeValue="employeeData.employeeNameAr = $event"
          :title="$t('employeeNameAr')"
          :imgName="'man.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeNameEn'"
          :value="employeeData.employeeNameEn"
          v-on:changeValue="employeeData.employeeNameEn = $event"
          :title="$t('employeeNameEn')"
          :imgName="'man.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeNameUnd'"
          :value="employeeData.employeeNameUnd"
          v-on:changeValue="employeeData.employeeNameUnd = $event"
          :title="$t('employeeNameUnd')"
          :imgName="'man.svg'"
          :disabledStatus="disabledStatus"
        />

        <TelPicker
          :className="'col-md-6 col-lg-4'"
          :value="employeeData.employeeCCWithCCName"
          v-on:changeCC="employeeData.employeeCCWithCCName = $event"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeePhone'"
          :value="employeeData.employeePhone"
          v-on:changeValue="employeeData.employeePhone = $event"
          :title="$t('phoneNumber')"
          :imgName="'phone.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeUserName'"
          :value="employeeData.employeeUserName"
          v-on:changeValue="employeeData.employeeUserName = $event"
          :title="$t('userName')"
          :imgName="'man.svg'"
          :disabledStatus="disabledStatus"
        />

        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeEmail'"
          :value="employeeData.employeeEmail"
          v-on:changeValue="employeeData.employeeEmail = $event"
          :title="$t('email')"
          :imgName="'email.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomInput
          :className="'col-md-6 col-lg-4'"
          :id="'employeeCode'"
          :value="employeeData.employeeCode"
          v-on:changeValue="employeeData.employeeCode = $event"
          :title="$t('employeeCode')"
          :imgName="'code.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomSelectBox
          :className="'col-md-6 col-lg-4'"
          :id="'employeeTypeToken'"
          :value="
            !disabledStatus
              ? employeeData.employeeTypeToken
              : employeeData.employeeTypeNameCurrent
          "
          :options="employeeTypeTokenOptions"
          v-on:changeValue="employeeData.employeeTypeToken = $event"
          :title="$t('selectEmployeeType')"
          :imgName="'type.svg'"
          :disabledStatus="disabledStatus"
        />

        <CustomSelectBox
          :className="'col-md-6'"
          :id="'establishmentRoleToken'"
          :value="
            !disabledStatus
              ? employeeData.establishmentRoleToken
              : employeeData.establishmentRoleNameCurrent
          "
          :options="establishmentRoleTokenOptions"
          v-on:changeValue="employeeData.establishmentRoleToken = $event"
          :title="$t('setting.others.establishmentRolesMsgs.select')"
          :imgName="'establishment-roles.svg'"
          :disabledStatus="disabledStatus"
        />
        <CustomCheckbox
          :className="'col-md-6'"
          :value="employeeData.employeeActiveStatus"
          v-on:changeValue="employeeData.employeeActiveStatus = $event"
          :title="$t('employeeActiveStatus')"
          :imgName="'type.svg'"
          :disabledStatus="disabledStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, DEFAULT_IMG } from "@/utils/constants";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TelPicker from "@/components/general/TelPicker.vue";
import EstablishmentRole from "@/models/settings/settingsOther/establishmentRoles/EstablishmentRole";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";

export default {
  name: "EmployeeBasicData",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    TelPicker,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,

      employeeGenderTokenOptions: [],
      employeeQualificationTokenOptions: [],
      employeeMaritalTokenOptions: [],
      employeeMilitaryTokenOptions: [],
      employeeTypeTokenOptions: [],
      generalSpecialtyTokenOptions: [],
      specialSpecialtyTokenOptions: [],
      bloodTypeTokenOptions: [],
      scientificDegreeTokenOptions: [],
      establishmentRoleData: new EstablishmentRole(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: {
    employeeData: {
      type: Object,
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "employeeData.employeeCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.employeeCountryCode = cc[1];
      this.employeeData.employeeCountryCodeName = cc[0];
    },
  },
  methods: {
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      this.establishmentRoleTokenOptions = [];
      // this.establishmentRoleTokenOptions.push({
      //   value: "",
      //   text: this.$t("setting.others.establishmentRolesMsgs.select"),
      // });
      try {
        let response =
          await this.establishmentRoleData.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.establishmentRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeTypes() {
      this.isLoading = true;
      this.employeeTypeTokenOptions = [];
      // this.employeeTypeTokenOptions.push({
      //   value: "",
      //   text: this.$t("selectEmployeeType"),
      // });
      let employeeTypes =
        this.$store.getters.userData.constantLists.listEmployeeType;
      for (let item in employeeTypes) {
        this.employeeTypeTokenOptions.push({
          value: employeeTypes[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            employeeTypes[item]["itemNameAr"],
            employeeTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },

    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  async created() {
    this.getDialogOfEmployeeTypes();
    this.getDialogOfEstablishmentRoles();
  },
};
</script>

<style lang="scss"></style>
