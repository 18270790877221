var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"custom-tab-card"},[_c('div',{staticClass:"row"},[(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeeNationalNumberIssuingAuthorityAr,"title":_vm.$t('employeeWorkContracts.nationalNumberIssuingAuthorityAr'),"imgName":'nationalNumber.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeeNationalNumberIssuingAuthorityEn,"title":_vm.$t('employeeWorkContracts.nationalNumberIssuingAuthorityEn'),"imgName":'nationalNumber.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeeNationalNumberIssuingAuthorityUnd,"title":_vm.$t('employeeWorkContracts.nationalNumberIssuingAuthorityUnd'),"imgName":'nationalNumber.svg'}}):_vm._e(),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeNationalNumber',"value":_vm.employeeData.employeeNationalNumber,"title":_vm.$t('nationalNumber'),"imgName":'nationalNumber.svg',"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeNationalNumber = $event}}}),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.employeeData.employeeIssuanceDateNationalNumber,
            _vm.employeeData.employeeIssuanceTimeNationalNumber
          ),"title":_vm.$t('employeeWorkContracts.nationalNumberIssuingDateTime'),"imgName":'dateAndTime.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.employeeData.employeeExpiryDateNationalNumber,
            _vm.employeeData.employeeExpiryTimeNationalNumber
          ),"title":_vm.$t('employeeWorkContracts.nationalNumberExpiryDateTime'),"imgName":'dateAndTime.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeePassportNumberIssuingAuthorityAr,"title":_vm.$t('employeeWorkContracts.passportNumberIssuingAuthorityAr'),"imgName":'passportNumber.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeePassportNumberIssuingAuthorityEn,"title":_vm.$t('employeeWorkContracts.passportNumberIssuingAuthorityEn'),"imgName":'passportNumber.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeeData.employeePassportNumberIssuingAuthorityUnd,"title":_vm.$t('employeeWorkContracts.passportNumberIssuingAuthorityUnd'),"imgName":'passportNumber.svg'}}):_vm._e(),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeePassportNumber',"value":_vm.employeeData.employeePassportNumber,"title":_vm.$t('passportNumber'),"imgName":'passportNumber.svg',"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeePassportNumber = $event}}}),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.employeeData.employeeIssuanceDatePassportNumber,
            _vm.employeeData.employeeIssuanceTimePassportNumber
          ),"title":_vm.$t('employeeWorkContracts.passportNumberIssuingDateTime'),"imgName":'dateAndTime.svg'}}):_vm._e(),(_vm.disabledStatus)?_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
            _vm.employeeData.employeeExpiryDatePassportNumber,
            _vm.employeeData.employeeExpiryTimePassportNumber
          ),"title":_vm.$t('employeeWorkContracts.passportNumberExpiryDateTime'),"imgName":'dateAndTime.svg'}}):_vm._e(),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeDrivingLicenceNumber',"value":_vm.employeeData.employeeDrivingLicenceNumber,"title":_vm.$t('drivingLicenceNumber'),"imgName":'drivingLicense.svg',"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeDrivingLicenceNumber = $event}}}),_c('DatePicker',{attrs:{"className":'col-md-6',"id":'employeeDrivingLicenceExpireDate',"value":_vm.employeeData.employeeDrivingLicenceExpireDate,"title":_vm.$t('drivingLicenceExpireDate'),"language":_vm.language,"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeDrivingLicenceExpireDate = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-4',"id":'employeeCitizenShipTypeToken',"value":!_vm.disabledStatus
            ? _vm.employeeData.employeeCitizenShipTypeToken
            : _vm.employeeData.employeeCitizenShipTypeNameCurrent,"options":_vm.employeeCitizenShipTypeTokenOptions,"title":_vm.$t('selectCitizenShipType'),"imgName":'citizenship.svg',"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeCitizenShipTypeToken = $event}}}),_c('DatePicker',{attrs:{"className":'col-md-4',"id":'employeeResidencyStartDate',"value":_vm.employeeData.employeeResidencyStartDate,"title":_vm.$t('employeeResidencyStartDate'),"language":_vm.language,"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeResidencyStartDate = $event}}}),_c('DatePicker',{attrs:{"className":'col-md-4',"id":'employeeResidencyEndDate',"value":_vm.employeeData.employeeResidencyEndDate,"title":_vm.$t('employeeResidencyEndDate'),"language":_vm.language,"disabledStatus":_vm.disabledStatus},on:{"changeValue":function($event){_vm.employeeData.employeeResidencyEndDate = $event}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }